import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomeView.vue";
import About from "@/views/AboutView.vue";
import Profile from "@/views/ProfileView.vue";
import Bookmarks from "@/views/BookmarksView.vue";
import ArticleView from "@/views/ArticleView.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/about", component: About },
  { path: "/profile", component: Profile },
  { path: "/bookmarks", component: Bookmarks },
  { path: "/article/:id", component: ArticleView },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
