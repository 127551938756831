<script setup>
import { ref, onMounted } from "vue";
import { graphqlService } from "@/services/graphqlService";
import { useRoute } from "vue-router";
import { useSpeechSynthesis } from "@/composables/useSpeechSynthesis";

const post = ref(null);
const route = useRoute();
const speechSynthesis = useSpeechSynthesis();

const { text, isSpeaking, speakText } = speechSynthesis;

const fetchPost = async () => {
  const postId = route.params.id;
  try {
    post.value = await graphqlService.fetchPostById(postId);

    if (post.value) {
      text.value = post.value?.title;
    }
  } catch (error) {
    console.error("Error fetching post:", error);
  }
};

// Function to format the published date
const formatPublishedDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

onMounted(() => {
  window.scrollTo(0, 0);
  fetchPost();
});
</script>

<template>
  <div v-if="post" class="container mx-auto page-wrapper article-view">
    <div>
      <!-- <div>
        <label for="voices">Select Voice:</label>
        <select id="voices" v-model="selectedVoice">
          <option v-for="voice in voices" :key="voice.name" :value="voice">
            {{ voice.name }} ({{ voice.lang }})
          </option>
        </select>
      </div> -->
      <!-- <button @click="speakText">Speak</button>
      <button @click="cancelSpeech">Cancel</button>
      <p>Is Speaking: {{ isSpeaking }}</p> -->
    </div>

    <article>
      <header>
        <h1 class="text-2xl font-bold py-1 px-2">{{ post.title }}</h1>

        <div class="flex justify-between py-2 px-2">
          <div class="text-xs text-grayText1 flex items-center">
            <img
              :src="post?.author?.node?.avatar?.url"
              alt="Author Avatar"
              class="w-8 h-8 rounded-md mr-2"
            />
            <span class="font-bold text-xm text-grayText2">
              {{ post?.author?.node?.name }}
              <br />
              Fullstack Programmer
            </span>
          </div>
          <!-- Publish Date -->
          <p class="text-grayText2 px-2 text-sm flex items-end published-date">
            Published: {{ formatPublishedDate(post.date) }}
          </p>
        </div>
      </header>

      <img
        :src="post.featuredImage.node.sourceUrl"
        alt="Featured Image"
        class="w-screen sm:w-full h-auto mx-auto"
      />

      <!-- Categories & Tags -->
      <div class="py-2 px-2">
        <div class="mt-1">
          <span
            v-for="(category, index) in post.categories.nodes"
            :key="index"
            class="inline-flex items-center px-2 text-md text-primaryText bg-warning rounded-md mr-1"
          >
            {{ category.name }}
          </span>
          <span
            v-for="(tag, index) in post.tags.nodes"
            :key="index"
            class="inline-block bg-danger text-white text-md mr-1 px-1 mb-1 rounded-md"
          >
            {{ tag.name }}
          </span>
        </div>
      </div>

      <div v-html="post.content" class="post-content py-2 px-2"></div>
    </article>
  </div>
</template>

<style scoped></style>
