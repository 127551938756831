<!-- src/components/ArticleListItem.vue -->
<template>
  <!-- Single Article -->
  <router-link
    :to="`/article/${article.id}`"
    class="flex gap-4 p-1 pt-2 even:bg-standardGray odd:bg-thirdGray border border-gray-300 rounded-lg shadow-sm"
  >
    <!-- Thumbnail -->
    <div class="w-20 h-20 bg-gray-200 rounded-lg flex-shrink-0">
      <img
        v-if="article.featuredImage && article.featuredImage.node"
        :src="thumbnail()"
        alt="Article Thumbnail"
        class="w-full h-full object-cover rounded-lg"
      />
    </div>

    <div class="flex-1">
      <div>
        <h2 class="text-sm font-semibold text-primaryText list-item-heading">
          {{ decodeHtml(article.title) }}
        </h2>
        <p class="text-orange list-item-tags">
          <CategoryBadge
            v-for="(category, index) in article.categories.nodes"
            :key="index"
            :category="category"
            background="none"
            :separator="
              parseInt(index) < parseInt(article.categories.nodes.length) - 1
                ? ','
                : ''
            "
          />
        </p>
      </div>
      <div class="flex justify-between">
        <!-- Left-aligned item -->
        <p
          class="list-item-published-at text-grayText2 mr-2 italic text-right items-end"
        >
          Published: {{ formatPublishedDate(article.date) }}
        </p>
        <!-- Right-aligned item -->
        <p class="text-xs text-grayText1 italic flex items-center">
          <!-- Author image -->
          <img
            :src="article.author.node.avatar.url"
            alt="Author Avatar"
            class="w-6 h-6 rounded-full mr-1 ml-1"
          />
        </p>
      </div>
    </div>
  </router-link>
</template>

<script setup>
import { defineProps, ref } from "vue";
import CategoryBadge from "@/components/CategoryBadge.vue";

const props = defineProps({
  article: {
    type: Object,
    required: true,
  },
});

// Path to the fallback image
const fallbackImage = "https://blog.danyal.dk/images/post_default.png";

// Select correct thumbnail
const thumbnail = () => {
  let image = props.article.featuredImage.node.mediaDetails?.sizes.filter(
    (img) => img.height == "169" && img.width == "300"
  )[0]?.sourceUrl;

  if (!image) {
    image = props.article.featuredImage.node.mediaDetails?.sizes.filter(
      (img) => img.height > "169"
    )[0]?.sourceUrl;
  }

  return !image ? fallbackImage : image;
};

// Function to decode HTML entities
const decodeHtml = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

// Format the published date
const formatPublishedDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};
</script>

<style scoped>
/* CSS for limiting title to two lines and adding ellipsis */
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
