<template>
  <div class="article-page">
    <div class="flex justify-between">
      <h2 class="page-heading">Posts</h2>
      <!-- <router-link
        to="/profile"
        class="text-danger text-md mt-1 mr-1 underline underline-offset-1"
      >
        About Nexus Beloggers?
      </router-link> -->
    </div>
    <div
      class="articlelist-wrapper mt-1"
      v-if="!state.searchResults.length && !state.searchQuery"
    >
      <ArticleList v-show="posts.length" :articles="posts" />

      <div class="text-center mt-2">
        <button
          v-if="pageInfo.hasNextPage && !loading"
          @click="loadMore"
          class="bg-danger text-white text-lg py-1 px-2 rounded-md"
        >
          Load More
        </button>
        <!-- Loading Spinner -->
        <div v-if="loading" class="flex justify-center items-center">
          <svg class="animate-spin h-5 w-5 text-danger" viewBox="0 0 24 24">
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              fill="none"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 0116 0A8 8 0 014 12z"
            ></path>
          </svg>
          <span class="ml-2 text-danger font-semibold">Loading...</span>
        </div>
      </div>
    </div>

    <div v-if="!state.searchResults.length && state.searchQuery">
      <div class="p-6 bg-white rounded-lg shadow-md">
        <h2 class="text-xl font-semibold text-gray-800 mb-4">
          No Results Found
        </h2>
        <p class="text-gray-600">
          We couldn't find any articles matching your search. Please try
          different keywords or check back later.
        </p>
      </div>
    </div>
    <div v-else>
      <ArticleList :articles="state.searchResults" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { graphqlService } from "@/services/graphqlService"; // Adjust path as necessary
import { useSearchResults } from "@/composables/useSearchResults";
import ArticleList from "@/components/lists/ArticleList.vue";
import { useRoute } from "vue-router";

const route = useRoute();

const changedCategoryId = ref(route.query.category);
const posts = ref([]);
const pageInfo = ref({ hasNextPage: false, endCursor: null });
const loading = ref(false); // Loading state
const { state } = useSearchResults();

// Function to fetch posts from GraphQL
const fetchPosts1 = async (cursor = null, categoryId = null) => {
  try {
    loading.value = true; // Set loading to true before fetching
    const { posts: fetchedPosts, pageInfo: fetchedPageInfo } =
      await graphqlService.fetchPosts(cursor); // Pass cursor instead of page
    posts.value.push(...fetchedPosts); // Append new posts
    pageInfo.value = fetchedPageInfo; // Update pagination info
  } catch (error) {
    console.error("Error fetching posts:", error);
  } finally {
    loading.value = false; // Set loading to false after fetching
  }
};

const fetchPosts = async (cursor = null, perPage = 10, categoryId = null) => {
  try {
    loading.value = true;

    const { posts: fetchedPosts, pageInfo: fetchedPageInfo } = !categoryId
      ? await graphqlService.fetchPosts(cursor, perPage)
      : await graphqlService.fetchPosts(cursor, perPage, categoryId);

    posts.value.push(...fetchedPosts); // Append new posts
    pageInfo.value = fetchedPageInfo; // Update pagination info
  } catch (error) {
    console.error("Error fetching posts:", error);
  } finally {
    loading.value = false; // Set loading to false after fetching
  }
};

// Load more posts when button is clicked
const loadMore = () => {
  if (pageInfo.value.hasNextPage && !loading.value) {
    changedCategoryId.value
      ? fetchPosts(
          pageInfo.value.endCursor,
          10,
          parseInt(changedCategoryId.value)
        )
      : fetchPosts(pageInfo.value.endCursor, 10);
  }
};

// Fetch initial posts on component mount
onMounted(() => {
  changedCategoryId.value
    ? fetchPosts(null, 10, parseInt(changedCategoryId.value))
    : fetchPosts(null, 10);
});
</script>

<style scoped>
.container {
  max-width: 800px; /* Optional: Set a max width */
}
</style>
