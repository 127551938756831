<template>
  <div class="min-h-screen flex flex-col items-center py-2 px-3 page-wrapper">
    <!-- Section 1: About Nexus Bloggers -->
    <div class="bg-white shadow-md rounded-lg p-6 max-w-md w-full">
      <h1 class="text-2xl font-bold text-center mb-4">About Nexus Bloggers</h1>
      <p class="text-gray-700 mb-4 text-center">
        Nexus Bloggers is a hobby project aimed at creating a vibrant platform
        for our blog sites, leveraging innovative tools and frameworks. It
        reflects my passion for learning and experimenting with cutting-edge
        technologies.
      </p>
      <div class="space-y-2 lg:space-y-0 lg:space-x-4 mt-2 lg:mt-6">
        <div class="flex items-center">
          <a
            target="_blank"
            href="https://danyal.dk"
            class="mr-2 ml-2 opacity-70 hover:opacity-100"
          >
            <img
              src="/logos/danyal.dk_logo.svg"
              class="rounded-full"
              alt="danyal.dk"
            />
          </a>
          <a target="_blank" class="mr-2 ml-2 opacity-70 hover:opacity-100">
            <img
              src="/logos/aminah.dk_logo.svg"
              class="rounded-full"
              alt="aminah.dk"
            />
          </a>
          <a
            target="_blank"
            href="https://abdulraheem.dk"
            class="mr-2 ml-2 opacity-70 hover:opacity-100"
          >
            <img
              src="/logos/abdulraheem.dk_logo.svg"
              class="rounded-full"
              alt="abdulraheem.dk"
            />
          </a>
          <a
            target="_blank"
            href="https://abdulsamad.dk"
            class="mr-2 ml-2 opacity-70 hover:opacity-100"
          >
            <img
              src="/logos/abdulsamad.dk_logo.svg"
              class="rounded-full"
              alt="abdulsamad.dk"
            />
          </a>
          <a
            target="_blank"
            href="https://rida.dk"
            class="mr-2 ml-2 opacity-70 hover:opacity-100"
          >
            <img
              src="/logos/rida.dk_logo.svg"
              class="rounded-full"
              alt="rida.dk"
            />
          </a>
        </div>
      </div>
    </div>
    <!-- Profile Section -->
    <div
      class="bg-white shadow-md rounded-lg p-6 mt-2 max-w-md w-full text-center"
    >
      <!-- Welcome Message -->
      <h1 class="text-2xl font-bold text-gray-800 mb-4">About Me</h1>

      <!-- User Information -->
      <p class="text-gray-600 mb-4">
        I'm a skilled programmer specializing in Vue.js/Nuxt.js for front-end
        development and PHP Laravel for back-end solutions. I have a strong
        focus on API design and development, complemented by experience in web
        server setup and maintenance.
      </p>
      <p class="text-gray-600 mb-4">
        My versatile expertise ensures seamless creation and maintenance of web
        applications, covering everything from intuitive user interfaces to
        robust server-side functionality.
      </p>
      <p class="text-gray-600 mb-4">
        Passionate about coding and driven by a lifelong learning mindset, I
        invite you to explore more at danyal.dk.
      </p>
      <!-- Edit Button (optional) -->
      <a
        target="_blank"
        href="https://www.linkedin.com/in/danyalbutt/"
        class="bg-orange text-white py-3 px-3 rounded-md hover:bg-primary transition-colors"
      >
        <i class="fa-brands fa-linkedin fa-xl"></i>
        Follow me
      </a>
    </div>

    <!-- Section 2: About danyal.dk -->
    <div class="bg-white shadow-md rounded-lg p-6 mt-2 max-w-md w-full">
      <h1 class="text-2xl font-bold text-center mb-4">
        danyal.me or danyal.dk?
      </h1>
      <p class="text-gray-700 mb-4 text-center">
        danyal.me is an innovative web application designed to showcase the
        content from danyal.dk using cutting-edge technologies. It seamlessly
        presents the rich content of danyal.dk in a dynamic, modern web app
        format, offering a smooth user experience and leveraging the latest in
        web development to enhance performance, interactivity, and
        accessibility.
      </p>

      <p class="text-gray-700 mb-10 text-center">
        Thank you for visiting. We hope you find our content valuable and
        inspiring!
      </p>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<style scoped>
.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7fafc;
}

.profile-card {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.profile-icon {
  font-size: 4rem;
  color: #4c51bf;
  margin-bottom: 1rem;
}

.profile-header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2d3748;
  margin-bottom: 1rem;
}

.profile-text {
  color: #718096;
  margin-bottom: 1rem;
}

.edit-button {
  background-color: #4c51bf;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  text-decoration: none;
}

.edit-button:hover {
  background-color: #434190;
}
</style>
