<template>
  <div class="p-1">
    <div class="flex overflow-x-auto space-x-1 pb-2">
      <CategoryTabs :categories="categories" />
    </div>

    <div class="bg-appBaground2 rounded-md shadow-md p-1 space-y-6">
      <div class="col-span-12 lg:col-span-8 order-1 lg:order-2">
        <div class="home-view container mx-auto">
          <div>
            <ArticlePage />
          </div>
        </div>
      </div>

      <div class="p-4 col-span-12 lg:col-span-2 order-2 lg:order-1"></div>

      <div class="p-4 col-span-12 lg:col-span-2 order-3"></div>
    </div>
  </div>
</template>
<script setup>
import ArticlePage from "@/components/ArticlePage.vue";
import CategoryTabs from "@/components/category/CategoryTabs.vue";
import { ref, onMounted } from "vue";
import { graphqlService } from "@/services/graphqlService";

const categories = ref([]);

const fetchCategories = async () => {
  try {
    categories.value = await graphqlService.fetchCategories();
  } catch (error) {
    console.error("Error fetching categories:", error);
  }
};

onMounted(() => {
  fetchCategories();
});
</script>
