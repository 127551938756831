import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css"; // Import Tailwind CSS
import "@fortawesome/fontawesome-free/css/all.css";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css"; // Use a theme like Tomorrow

const script = document.createElement("script");

script.src =
  "https://danyal.dk/wp-content/plugins/highlighting-code-block/build/js/hcb_script.js?ver=2.0.1";
script.async = true;
document.head.appendChild(script);
const link = document.createElement("link");
link.rel = "stylesheet";
link.href =
  "https://danyal.dk/wp-content/plugins/highlighting-code-block/build/css/hcb--light.css?ver=2.0.1";
document.head.appendChild(link);

createApp(App).use(router).mount("#app");
