import { reactive } from "vue";

// Create a shared state for search results
const state = reactive({
  searchQuery: null,
  searchResults: [],
});

// Function to set search results
const setSearchResults = (results) => {
  state.searchResults = results;
};

// Function to clear search results
const clearSearchResults = () => {
  state.searchResults = [];
};

// Return the shared state and methods
export const useSearchResults = () => {
  return {
    state,
    setSearchResults,
    clearSearchResults,
  };
};
