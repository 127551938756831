<template>
  <div class="min-h-screen flex flex-col items-center py-2 px-3">
    <!-- Profile Section -->
    <div class="bg-white shadow-md rounded-lg p-6 max-w-md w-full text-center">
      <!-- User Icon -->
      <i class="fas fa-bookmark text-6xl text-warningText mb-4"></i>

      <!-- Welcome Message -->
      <h1 class="text-2xl font-bold text-gray-800 mb-4">Bookmarks</h1>

      <!-- User Information -->
      <p class="text-gray-600 mb-4">Nothing yet bookmarked!</p>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>

<style scoped></style>
