<template>
  <nav class="bg-primary text-white sticky top-0 z-50">
    <div class="container mx-auto flex justify-center items-center p-4">
      <!-- Logo Section -->
      <div class="flex items-center space-x-2 brand">
        <router-link to="/" class="text-md"> Nexus Bloggers </router-link>
      </div>
      <!-- <div class="hidden md:flex space-x-4">
        <router-link
          to="/"
          class="text-blue-400 hover:text-blue-300"
          active-class="font-bold"
        >
          Home
        </router-link>
        <router-link
          to="/about"
          class="text-blue-400 hover:text-blue-300"
          active-class="font-bold"
        >
          About
        </router-link>
      </div>
      <div class="md:hidden">
        <button @click="toggleMenu" class="focus:outline-none">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
      </div> -->
    </div>
    <!-- <div
      :class="{ block: isOpen, hidden: !isOpen }"
      id="mobile-menu"
      class="md:hidden"
    >
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <router-link
          to="/"
          class="text-gray-300 hover:bg-danger hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          active-class="bg-gray-700  font-bold"
          @click="toggleMenu"
        >
          Home
        </router-link>
        <router-link
          to="/about"
          class="text-gray-300 hover:bg-danger hover:text-white block px-3 py-2 rounded-md text-base font-medium"
          active-class="bg-gray-700  font-bold"
          @click="toggleMenu"
        >
          About
        </router-link>
      </div>
    </div> -->
  </nav>
</template>

<script setup>
import { ref } from "vue";

const isOpen = ref(false);

const toggleMenu = () => {
  isOpen.value = !isOpen.value;
};
</script>

<style scoped>
/* Add any scoped styles here */
</style>
