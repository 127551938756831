import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { provideApolloClient } from "@vue/apollo-composable";
import gql from "graphql-tag"; // Import gql from graphql-tag

// Initialize Apollo Client
const client = new ApolloClient({
  uri: "https://danyal.dk/graphql", // Your WordPress GraphQL endpoint
  cache: new InMemoryCache(),
});

// Provide Apollo Client to the Vue app
provideApolloClient(client);

export const graphqlService = {
  async fetchPosts(cursor = null, perPage = 10, categoryId = null) {
    const GET_POSTS = gql`
      query GetPosts($cursor: String, $perPage: Int!, $categoryId: Int) {
        posts(
          first: $perPage
          after: $cursor
          where: { categoryId: $categoryId }
        ) {
          nodes {
            id
            title
            content
            date
            featuredImage {
              node {
                sourceUrl
                mediaDetails {
                  file
                  height
                  width
                  sizes {
                    height
                    sourceUrl
                    width
                  }
                }
              }
            }
            categories {
              nodes {
                name
              }
            }
            author {
              node {
                id
                name
                slug
                avatar {
                  url
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `;

    try {
      if (cursor && typeof cursor !== "string") {
        cursor = null;
      }

      const { data } = await client.query({
        query: GET_POSTS,
        variables: {
          cursor: cursor,
          perPage: perPage,
          categoryId: categoryId,
        },
      });

      return {
        posts: data.posts.nodes,
        pageInfo: data.posts.pageInfo,
      };
    } catch (error) {
      console.error("Error fetching posts:", error);
      throw error;
    }
  },

  async fetchPostsByCategoryId(cursor = null, perPage = 10, categoryId = null) {
    const GET_POSTS_BY_CATEGORY_ID = gql`
      query GetPosts($cursor: String, $perPage: Int!, $categoryId: Int) {
        posts(
          first: $perPage
          after: $cursor
          where: { categoryId: $categoryId }
        ) {
          nodes {
            id
            title
            content
            date
            featuredImage {
              node {
                sourceUrl
                mediaDetails {
                  file
                  height
                  width
                  sizes {
                    height
                    sourceUrl
                    width
                  }
                }
              }
            }
            categories {
              nodes {
                name
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `;

    try {
      if (cursor && typeof cursor !== "string") {
        cursor = null;
      }

      const { data } = await client.query({
        query: GET_POSTS_BY_CATEGORY_ID,
        variables: {
          cursor: cursor, // Properly pass null for the first request, or a string for subsequent requests
          perPage: perPage,
          categoryId: categoryId, // Pass the categoryId as a variable
        },
      });

      return {
        posts: data.posts.nodes,
        pageInfo: data.posts.pageInfo,
      };
    } catch (error) {
      console.error("Error fetching posts:", error);
      throw error;
    }
  },
  async fetchPostById(id) {
    const GET_POST = gql`
      query GetPost($id: ID!) {
        post(id: $id) {
          id
          databaseId
          title
          content
          date
          featuredImage {
            node {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
          tags {
            nodes {
              name
            }
          }
          author {
            node {
              id
              name
              slug
              avatar {
                url
              }
            }
          }
        }
      }
    `;

    try {
      const { data } = await client.query({
        query: GET_POST,
        variables: { id }, // Pass the id variable
      });

      return data.post;
    } catch (error) {
      console.error("Error fetching post:", error);
      throw error;
    }
  },
  async searchPosts(query, cursor = null, perPage = 10) {
    const SEARCH_POSTS = gql`
      query SearchPosts($query: String!, $cursor: String, $perPage: Int!) {
        posts(first: $perPage, after: $cursor, where: { search: $query }) {
          nodes {
            id
            title
            content
            date
            featuredImage {
              node {
                sourceUrl
                mediaDetails {
                  file
                  height
                  width
                  sizes {
                    height
                    sourceUrl
                    width
                  }
                }
              }
            }
            categories {
              nodes {
                name
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `;

    try {
      const { data } = await client.query({
        query: SEARCH_POSTS,
        variables: {
          query: query,
          cursor: cursor,
          perPage: perPage,
        },
      });

      return {
        posts: data.posts.nodes,
        pageInfo: data.posts.pageInfo,
      };
    } catch (error) {
      console.error("Error searching posts:", error);
      throw error;
    }
  },

  async fetchCategories() {
    const GET_CATEGORIES_QUERY = gql`
      query GetCategories {
        categories(where: { orderby: COUNT, order: DESC }) {
          nodes {
            id
            databaseId
            name
            slug
            count
          }
        }
      }
    `;

    try {
      const response = await client.query({
        query: GET_CATEGORIES_QUERY,
      });
      return response.data.categories.nodes;
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  },
};
