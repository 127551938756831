import { ref, onMounted } from "vue";

export const useSpeechSynthesis = () => {
  const text = ref("");
  const isSpeaking = ref(false);
  const voices = ref([]);
  const selectedVoice = ref(null);
  const speakText = () => {
    if (!window.speechSynthesis) {
      console.error("SpeechSynthesis is not supported in this browser.");
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text.value);
    utterance.voice = selectedVoice.value || null;
    utterance.pitch = 1;
    utterance.rate = 1;
    utterance.volume = 1;

    utterance.onstart = () => (isSpeaking.value = true);
    utterance.onend = () => (isSpeaking.value = false);

    utterance.addEventListener("start", () => {
      isSpeaking.value = true;
    });
    utterance.addEventListener("end", () => {
      isSpeaking.value = false;
    });

    window.speechSynthesis.speak(utterance);
  };

  const cancelSpeech = () => {
    if (window.speechSynthesis) {
      window.speechSynthesis.cancel();
      isSpeaking.value = false;
    }
  };

  const loadVoices = () => {
    if (window.speechSynthesis) {
      voices.value = window.speechSynthesis.getVoices();
      if (voices.value.length > 0) {
        selectedVoice.value = voices.value[0];
      }
    }
  };

  onMounted(() => {
    if (typeof window !== "undefined" && window.speechSynthesis) {
      window.speechSynthesis.onvoiceschanged = loadVoices;
      loadVoices();
    }
  });

  return {
    text,
    isSpeaking,
    voices,
    selectedVoice,
    speakText,
    cancelSpeech,
  };
};
