<template>
  <div
    class="min-h-screen flex flex-col items-center justify-center space-y-3 py-2 page-wrapper"
  >
    <!-- Section 3: App Overview -->
    <div class="bg-white shadow-md rounded-lg p-6 max-w-md w-full">
      <div class="text-gray-700 mb-4">
        <div class="mb-6 text-left">
          <h1 class="text-2xl font-bold text-center mb-4 mt-3">
            App Overview: <br />
            Headless CMS Architecture
          </h1>
          <img
            alt="App Architecture"
            src="/headless_cms_app.png"
            class="mx-auto rounded-full object-cover"
          />
          <hr class="my-4" />

          <h2 class="text-xl font-semibold text-gray-700 mb-2 mt-2">
            1. Backend (CMS): WordPress
          </h2>
          <p class="text-gray-600 mb-2">
            <strong>Purpose:</strong> Acts as the Content Management System
            (CMS).
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Content creation and management are handled in WordPress.</li>
            <li>Flexible post types, taxonomies, and metadata structures.</li>
            <li>
              Plugins like ACF (Advanced Custom Fields) can enhance flexibility.
            </li>
          </ul>
        </div>
        <div class="mb-6">
          <h2 class="text-xl font-semibold text-gray-700 mb-2">
            2. API Gateway: WPGraphQL
          </h2>
          <p class="text-gray-600 mb-2">
            <strong>Purpose:</strong> Provides a GraphQL API layer to query
            WordPress data.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Optimized for fetching specific data via GraphQL queries.</li>
            <li>
              Reduces over-fetching by allowing fine-grained data selection.
            </li>
            <li>
              Secure integration with WordPress user roles and authentication
              mechanisms.
            </li>
            <li>Extendable with custom resolvers for additional data.</li>
          </ul>
        </div>
        <div class="mb-6">
          <h2 class="text-xl font-semibold text-gray-700 mb-2">
            3. Frontend: Vue.js 3
          </h2>
          <p class="text-gray-600 mb-2">
            <strong>Purpose:</strong> Provides a modern, interactive, and
            dynamic user interface.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li><strong>Framework:</strong> Vue 3 with Composition API.</li>
            <li><strong>State Management:</strong> Pinia or Vuex.</li>
            <li>
              <strong>GraphQL Client:</strong> Apollo Client for GraphQL
              queries.
            </li>
            <li>
              <strong>CSS Framework:</strong> Optionally uses TailwindCSS for
              responsive design.
            </li>
            <li><strong>Routing:</strong> Vue Router for page navigation.</li>
          </ul>
        </div>

        <!-- Data Flow Section -->
        <!-- <div class="mb-6">
          <h2 class="text-xl font-semibold text-gray-700 mb-2">4. Data Flow</h2>
          <ol class="list-decimal list-inside text-gray-600">
            <li>
              <strong>Content Management:</strong> Administrators create or
              update content in WordPress.
            </li>
            <li>
              <strong>API Integration:</strong> Frontend queries WordPress data
              using WPGraphQL queries.
            </li>
            <li>
              <strong>Frontend Rendering:</strong> Vue.js dynamically renders
              the data fetched via GraphQL.
            </li>
          </ol>
        </div> -->

        <!-- Advantages Section -->
        <!-- <div class="mb-6">
          <h2 class="text-xl font-semibold text-gray-700 mb-2">
            Why This Architecture?
          </h2>
          <ul class="list-disc list-inside text-gray-600">
            <li>
              <strong>Scalability:</strong> Decouples the backend and frontend
              for independent scaling.
            </li>
            <li>
              <strong>Flexibility:</strong> The Vue.js frontend can serve
              multiple platforms.
            </li>
            <li>
              <strong>Improved Performance:</strong> Fine-grained control over
              data fetching with GraphQL.
            </li>
            <li>
              <strong>Custom Design:</strong> Frontend design is unrestricted by
              WordPress themes or plugins.
            </li>
          </ul>
        </div> -->

        <!-- Challenges Section -->
        <!-- <div>
          <h2 class="text-xl font-semibold text-gray-700 mb-2">
            Potential Challenges
          </h2>
          <ul class="list-disc list-inside text-gray-600">
            <li>
              <strong>Performance Optimization:</strong> Caching queries in
              WPGraphQL and using CDN for static assets.
            </li>
            <li>
              <strong>SEO:</strong> Implement server-side rendering (SSR) or
              static site generation (SSG) for better SEO.
            </li>
            <li>
              <strong>Data Security:</strong> Use proper authentication (e.g.,
              JWT) and secure API exposure.
            </li>
          </ul>
        </div> -->

        <!-- More sections can be added here -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
  window.scrollTo(0, 0);
});
</script>
<style scoped>
/* Add any scoped styles here if necessary */
</style>
