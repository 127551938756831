<template>
  <router-link
    to="/"
    class="px-4 py-2 text-sm font-semibold text-white bg-orange rounded-lg whitespace-nowrap"
    :class="{
      'bg-primaryGray text-white hover:bg-primaryGray':
        hasQueryStringCategory(),
      'bg-orange text-white': !hasQueryStringCategory(),
    }"
  >
    All
  </router-link>
  <router-link
    v-for="category in categories.slice(0, 5)"
    :key="category.databaseId"
    :to="`/?category=${category.databaseId}`"
    class="px-4 py-2 text-sm font-semibold rounded-lg whitespace-nowrap"
    :class="{
      'bg-primaryGray text-white hover:bg-primaryGray': !isSelected(
        category.databaseId
      ),
      'bg-orange text-white': isSelected(category.databaseId),
    }"
  >
    {{ category.name }} ({{ category.count }})
  </router-link>
</template>

<script setup>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

// Props
defineProps({
  categories: {
    type: Array,
    required: true,
  },
});

// Route and router
const route = useRoute();
const router = useRouter();

const isSelected = (categoryId) => {
  return route.query.category === String(categoryId);
};

const hasQueryStringCategory = () => {
  return route.query.category;
};
</script>

<style scoped>
/* Add custom styles if needed */
</style>
