<template>
  <footer class="fixed bottom-0 left-0 w-full bg-primary text-white">
    <div class="container mx-auto px-4 py-3">
      <div class="flex justify-center items-center space-x-8">
        <!-- Navigation Tabs with Font Awesome Icons -->
        <router-link
          to="/"
          class="flex flex-col items-center hover:text-warningText"
          :class="tabClasses('/')"
        >
          <i class="fas fa-home text-2xl mb-1"></i>
        </router-link>

        <router-link
          to="/bookmarks"
          class="flex flex-col items-center hover:text-warningText"
          :class="tabClasses('/bookmarks')"
        >
          <i class="fas fa-bookmark text-2xl mb-1"></i>
        </router-link>

        <router-link
          to="/profile"
          class="flex flex-col items-center hover:text-warningText"
          :class="tabClasses('/profile')"
        >
          <i class="fa-solid fa-id-card-clip text-2xl mb-1"></i>
        </router-link>

        <!-- <button
          class="flex flex-col items-center hover:text-warningText focus:outline-none"
          @click="toggleDarkMode"
        >
          <i
            :class="`fas text-2xl mb-1 ${isDarkMode ? 'fa-sun' : 'fa-moon'}`"
          ></i>
        </button> -->

        <router-link
          to="/about"
          class="flex flex-col items-center hover:text-warningText"
          :class="tabClasses('/about')"
        >
          <i class="fas fa-info-circle text-2xl mb-1"></i>
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref } from "vue";

// Get current route
const route = useRoute();
// const isDarkMode = ref(false);

// Check initial theme
const isDarkMode = ref(
  localStorage.getItem("theme") === "dark" ||
    window.matchMedia("(prefers-color-scheme: dark)").matches
);

// Update theme on mount
if (isDarkMode.value) {
  document.documentElement.classList.add("dark");
}

// Check if the tab is active based on the route
const tabClasses = (path) => {
  return route.path === path ? "text-warningText" : "text-white";
};

// Toggle Dark Mode
const toggleDarkMode = () => {
  isDarkMode.value = !isDarkMode.value;
  const theme = isDarkMode.value ? "dark" : "light";
  localStorage.setItem("theme", theme);
  document.documentElement.classList.toggle("dark", isDarkMode.value);
};
</script>

<style scoped>
footer {
  font-size: 0.875rem;
}

footer a {
  text-decoration: none;
}

footer a:hover {
  text-decoration: none;
}

footer i {
  transition: color 0.3s ease;
}
</style>
